import React from 'react'

import BasicLayout from '../../components/layout/BasicLayout';
import {Link} from "gatsby";
import ContactForm from "../../components/ContactForm";
import Content from "../../components/Content";

export default ({}) => {
    const content = (
        <div>
            <p>
                I offer a range of services including <Link to="/services/ne-php-developer">php development</Link>, <Link to="/services/frontend-development">frontend development</Link> & <Link to="/services/maintenance-work">maintenance work</Link>.
                I’m based in Gateshead but also work in and around Newcastle and Leeds.
            </p>

            <p>
                If you require more details, feel free to get in touch.
            </p>
        </div>
    );

    return (
        <BasicLayout pageName={"services"} title={"John Richardson, PHP Developer - Services"}>
            <section>
                <h1 className={"copy-header copy-header--xl"}>
                    Services
                </h1>

                <Content className="l-component content" content={content} />

                <div className="l-component">
                    <ContactForm></ContactForm>
                </div>
            </section>
        </BasicLayout>
    );
}
